/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'blockquote-right': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.5 3a.5.5 0 000 1h11a.5.5 0 000-1zm0 3a.5.5 0 000 1h6a.5.5 0 000-1zm0 3a.5.5 0 000 1h6a.5.5 0 000-1zm0 3a.5.5 0 000 1h11a.5.5 0 000-1zm10.113-5.373a7 7 0 00-.445-.275l.21-.352q.183.111.452.287.27.176.51.428.234.246.398.562.164.31.164.692 0 .54-.216.873-.217.328-.721.328-.322 0-.504-.211a.7.7 0 01-.188-.463q0-.345.211-.521.205-.182.569-.182h.281a1.7 1.7 0 00-.123-.498 1.4 1.4 0 00-.252-.37 2 2 0 00-.346-.298m-2.168 0A7 7 0 0010 6.352L10.21 6q.183.111.452.287.27.176.51.428.234.246.398.562.164.31.164.692 0 .54-.216.873-.217.328-.721.328-.322 0-.504-.211a.7.7 0 01-.188-.463q0-.345.211-.521.206-.182.569-.182h.281a1.8 1.8 0 00-.117-.492 1.4 1.4 0 00-.258-.375 2 2 0 00-.346-.3z"/>',
    },
});
